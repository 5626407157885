import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-bootstrap';
import { SiteConfig } from '../components/config';
import { Layout } from '../components/layout';
import { ExternalLink } from '../components/links';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

class Document extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  };

  render() {
    const { title, url } = this.props;
    return (
      <div className="h-documents-link">
        <ExternalLink url={url}>
          {title}
          <FontAwesomeIcon
            className="h-documents-icon"
            icon={faExternalLinkAlt}
          />
        </ExternalLink>
      </div>
    );
  }
}

export default class DocumentsPage extends React.Component {
  render() {
    return (
      <SiteConfig>
        {({
          termsUrl,
          createTermsUrl,
          privacyPolicyUrl,
          modelLicenseAgreementUrl,
          thirdPartySoftwareUrl,
        }) => (
          <Layout className="h-documents-page" title="Documents">
            <Container>
              <h1>Documents</h1>
              <Row>
                <Col xs={12} lg={4}>
                  <h2>Terms</h2>
                  <Document title="Hone Terms" url={termsUrl} />
                  <Document title="Hone Create Terms" url={createTermsUrl} />
                  <Document
                    title="Third-party Software"
                    url={thirdPartySoftwareUrl}
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <h2>Privacy</h2>
                  <Document
                    title="Hone Privacy Policy"
                    url={privacyPolicyUrl}
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <h2>Agreements</h2>
                  <Document
                    title="Hone Model License Agreement"
                    url={modelLicenseAgreementUrl}
                  />
                </Col>
              </Row>
            </Container>
          </Layout>
        )}
      </SiteConfig>
    );
  }
}
